import React from 'react'

import Box from 'components/Box'
import Typography from 'components/Typography'


const OnBoarding = () => (
  <Box
    display="flex"
    flexDirection="column"
    alignItems="center"
    justifyContent="center"
    height="100%"
  >
    <Typography variant="typo12" mb={42} color="gray.0">
      录播搜索引擎
    </Typography>
  </Box>
)

export default OnBoarding
