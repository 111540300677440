import React from 'react'
import styled from 'styled-components'
import Color from 'color'

import { useMeilisearchClientContext } from 'context/MeilisearchClientContext'
import NoSelectOption from 'components/NoSelectOption'
import SearchBox from 'components/SearchBox'
import Box from 'components/Box'
import Container from 'components/Container'
import Select from 'components/Select'
import { Indexes } from 'components/icons'
import { compose, position } from 'styled-system'
import Typography from '../Typography'

const HeaderWrapper = styled('div')(compose(position), {
  backgroundColor: 'white',
  display: 'flex',
  position: 'sticky',
  height: '120px',
  boxShadow: `0px 0px 30px ${(p) => Color(p.theme.colors.gray[0]).alpha(0.15)}`,
  zIndex: 3,
})



const Header = ({
  indexes,
  currentIndex,
  setCurrentIndex,
  refreshIndexes,
  isBannerVisible,
}) => {
  const { meilisearchJsClient } = useMeilisearchClientContext()
  const [ setVersion] = React.useState()

  React.useEffect(() => {
    const getMeilisearchVersion = async () => {
      try {
        const res = await meilisearchJsClient.getVersion()
        setVersion(res.pkgVersion)
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err)
      }
    }
    getMeilisearchVersion()
  }, [meilisearchJsClient])

  return (
    <HeaderWrapper top={isBannerVisible ? 55 : 0}>
      <Container
        p={4}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        height="100%"
      >
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          {/* Trick to make the logo look centered */}
          <Typography variant="typo1"  color="gray.0">
            录播搜索引擎
          </Typography>
        </Box>
        <Box display="flex">
          <SearchBox
            refreshIndexes={refreshIndexes}
            currentIndex={currentIndex}
          />
          <Select
            options={indexes}
            icon={<Indexes style={{ height: 22 }} />}
            currentOption={currentIndex}
            onChange={setCurrentIndex}
            noOptionComponent={<NoSelectOption />}
            style={{ width: 216 }}
            onClick={refreshIndexes}
          />
        </Box>
      </Container>
    </HeaderWrapper>
  )
}

export default Header
