import React, { useState, useRef, useEffect } from 'react';
import WavesurferPlayer from '@wavesurfer/react';
import './APlay.css'; // Import the CSS file

const APlay = ({ url,name }) => {
  const [wavesurfer, setWavesurfer] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const containerRef = useRef(null);

  const onReady = (ws) => {
    setWavesurfer(ws);
    setIsPlaying(false);
  };

  const onPlayPause = () => {
    if (wavesurfer) {
      wavesurfer.playPause();
      setIsPlaying(!isPlaying);
    }
  };
  const Play = () => {
      wavesurfer.play();
      setIsPlaying(true);
  };
  const handleKeyDown = (event) => {
    if (event.code === 'Space') {
      event.preventDefault();
      onPlayPause();
    }
  };

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.addEventListener('keydown', handleKeyDown);
    }
    return () => {
      if (container) {
        container.removeEventListener('keydown', handleKeyDown);
      }
    };
  }, [wavesurfer, isPlaying]);

  return (
    <div ref={containerRef} style={{ outline: 'none' }}>
      <div tabIndex="-1">
        <WavesurferPlayer
          height={50}
          waveColor="#87CEFA"
          url={url}
          onReady={onReady}
          onPlay={() => setIsPlaying(true)}
          onPause={() => setIsPlaying(false)}
          onClick={() => Play()}
          dragToSeek
        />
        <button type="button" className="play-pause-button" onClick={onPlayPause}>
          {isPlaying ? 'Pause' : 'Play'}
        </button>
        <button type="button" className="download-button"   onClick={() => window.open(`${url}&name=${encodeURIComponent(name)}&download=1`)}>Download</button>
      </div>
    </div>
  );
};

export default APlay;